import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <a href="https://www.youtube.com/@TheFinestX" target="_blank">
              <img src="/assets/images/brand/The_Finest.png" alt="The Finest" />
            </a>
          </li>

          <li>
            <a href="https://www.youtube.com/@chills" target="_blank">
              <img src="/assets/images/brand/chills.png" alt="Amazing Banana" />
            </a>
          </li>

          <li>
            <a href="https://www.youtube.com/@Top15s/videos" target="_blank">
              <img src="/assets/images/brand/top15.png" alt="Top15" />
            </a>
          </li>

          <li>
            <a
              href="https://www.youtube.com/@sleepiscanceled/videos"
              target="_blank"
            >
              <img
                src="/assets/images/brand/Sleep_Is_Cancelled.png"
                alt="Sleep Is Cancelled"
              />
            </a>
          </li>

          <li>
            <a
              href="https://youtube.com/@scaryencounter1?si=vN9flB16gQshxXvN"
              target="_blank"
            >
              <img
                src="/assets/images/brand/scary-encounter.png"
                alt="Reel central"
              />
            </a>
          </li>

          <li>
            <a href="https://www.youtube.com/@ReelCentral" target="_blank">
              <img
                src="/assets/images/brand/Reel_central.png"
                alt="Reel central"
              />
            </a>
          </li>

          <li>
            <a
              href="https://www.youtube.com/@moviesexplainedvideos"
              target="_blank"
            >
              <img
                src="/assets/images/brand/movie_explained.png"
                alt="Movie Explained"
              />
            </a>
          </li>

          <li>
            <a
              href="https://www.youtube.com/@TheForgotten-BV/videos"
              target="_blank"
            >
              <img
                height={200}
                src="/assets/images/brand/the_forgotten.png"
                alt="Top Discovery"
              />
            </a>
          </li>

          <li>
            <a href="https://www.youtube.com/@TopMysteryVideos" target="_blank">
              <img
                src="/assets/images/brand/Top_Mystery.png"
                alt="Top Mystery"
              />
            </a>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
